import React from "react";
import ReactDomServer from "react-dom/server";
import { Footer, } from "@kmdpartners/ui-kit/src/CN_Design_System";
import { DEFAULTS } from '@kmdpartners/ui-kit/src/CN_Design_System/blocks/Footer/constants';
function App() {
    var _a;
    const _linksModifier = (link, index) => {
        if ([8, 9, 10].includes(index)) {
            return Object.assign(Object.assign({}, link), { target: '_blank', href: [
                    'https://creditninja.com/docs/privacy-notice.pdf',
                    'https://www.creditninja.com/assets/feb-privacy-policy-f9801ce501211526f4f1f761e22b352a713ff72cb8db3067af742562cb9a6856.pdf',
                    'https://www.creditninja.com/assets/account-opening-disclosure-1a53a23a783cd07d783b21303ca2a829553b0fb3c6c63ce3e5cf19ec4b2fb36d.pdf'
                ][index - 8] });
        }
        return Object.assign(Object.assign({}, link), { target: '_self' });
    };
    const footerData = Object.assign(Object.assign({}, DEFAULTS), { links: (_a = DEFAULTS.links) === null || _a === void 0 ? void 0 : _a.map(_linksModifier) });
    return (React.createElement("div", null,
        React.createElement(Footer, { dataToAppear: footerData })));
}
if (window == undefined) {
    const ssrContent = ReactDomServer.renderToString(React.createElement(App, null));
    console.log("SSR Content", ssrContent);
}
export default App;
